import { Injectable } from '@angular/core';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  constructor() {}

  async requestPermission(): Promise<boolean> {
    if (this.isFirefox || Capacitor.isNativePlatform()) {
      return true;
    }
    return (
      navigator.permissions
        // @ts-ignore
        .query({ name: 'camera' })
        .then((result: any) => {
          if (result.state == 'granted') {
            return true;
          } else if (result.state == 'prompt') {
            return navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((stream) => {
                return true;
              })
              .catch((error) => {
                console.error('Error requesting camera permission', error);
                return false;
              });
          } else if (result.state == 'denied') {
            // Permission was denied
            return false;
          }
          return false;
        })
    );
  }

  private isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;

  async requestMicrophonePermission(): Promise<boolean> {
    try {
      const result = await VoiceRecorder.requestAudioRecordingPermission();
      return result.value;
    } catch (error) {
      console.error('Error requesting microphone permission', error);
      return false;
    }
  }
}

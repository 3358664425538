import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SoundTypeEnum } from '../models/Sound';
import { SoundService } from '../../core/services/sound.service';

@Directive({
  selector: '[tmuPlaysSoundOnClick]',
  standalone: true,
})
export class PlaysSoundOnClickDirective {
  constructor(
    private soundService: SoundService,
    private el: ElementRef,
  ) {}

  @Input('tmuPlaysSoundOnClick') soundType: SoundTypeEnum | undefined;

  @HostListener('click') onClick() {
    if (this.soundType) {
      this.soundService.playSound(this.soundType);
    } else {
      console.warn(
        'Sound type is not specified for appPlaySoundOnClick directive',
      );
    }
  }
}

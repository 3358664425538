@if (formActivated$ | async) {
  <app-ar (imageTakenChange)="imageTakenChange($event)"></app-ar>
} @else {
  @if (teamImage$ | async; as teamImage) {
    <img
      [src]="teamImage"
      (click)="takePhoto()"
      [class.rounded-circle]="rounded"
      [class.rounded-1]="!rounded"
      class="p-2 img-fluid border border-4 border-primary"
      alt
      [width]="size"
      [height]="size"
    />
  } @else {
    <img
      [class.rounded-circle]="rounded"
      [class.rounded-1]="!rounded"
      (click)="takePhoto()"
      [src]="defaultImage"
      width="100%"
      alt="Camera"
    />
  }
}

@if (team) {
  <img
    [style.border-color]="team.color + '!important'"
    [ngSrc]="team.teamPhoto"
    tmuImageFallback
    priority
    class="img-fluid rounded-circle border border-4"
    width="200"
    height="200"
  />
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Team } from '@freddy/models';
import { NgOptimizedImage } from '@angular/common';
import { ImageFallbackDirective } from '../../directives/image-fallback.directive';

@Component({
    selector: 'tmu-team-photo',
    templateUrl: './team-photo.component.html',
    styleUrls: ['./team-photo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgOptimizedImage, ImageFallbackDirective]
})
export class TeamPhotoComponent {
  @Input()
  team: Team | null = null;
}

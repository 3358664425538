<div class="page">
  <header>
    <ng-content select="[page-header]"></ng-content>
  </header>
  <main>
    <ng-content select="[page-main]"></ng-content>
  </main>
  <footer>
    <ng-content select="[page-footer]"></ng-content>
  </footer>
</div>

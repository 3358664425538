<button
  [disabled]="disabled"
  class="btn btn-lg btn-primary"
  [ngClass]="{ loading: loading }"
>
  @if (showSpinner | async) {
    <span class="spinner"></span>
  }
  <ng-container>
    <ng-content></ng-content>
  </ng-container>
</button>

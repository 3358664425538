export class VideoUtils {
  // Static method to get the first supported MIME type from a list of preferences
  static getSupportedMimeType(preferredMimeTypes: string[]) {
    for (const mimeType of preferredMimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        return mimeType; // Return the first supported MIME type from preferences
      }
    }
    return null; // Indicate that none of the preferred MIME types are supported
  }

  // Static method to select the best video MIME type
  static selectBestVideoMimeType() {
    // Expanded list of MIME types in order of preference
    const preferredMimeTypes = [
      'video/webm; codecs=vp8', // Widely supported, good balance between quality and compression
      'video/mp4; codecs="avc1.42E01E"', // Widely supported H.264 video codec in MP4 container
      'video/mp4; codecs="hev1"', // H.265 (HEVC) for better compression than H.264 (less widely supported)
      'video/mp4; codecs="hvc1"', // Alternative notation for H.265 (HEVC) video codec
      'video/webm; codecs=vp8,opus', // WebM with VP8 video and Opus audio codecs
      // Add other preferred MIME types here as needed
    ];

    // Attempt to find a supported MIME type from the preferred list
    const supportedMimeType =
      VideoUtils.getSupportedMimeType(preferredMimeTypes);

    if (supportedMimeType) {
      return supportedMimeType;
    } else {
      // No preferred MIME type is supported, attempt to use the browser's default
      try {
        // Attempt to create a MediaRecorder with default settings to determine the default MIME type
        const stream = new MediaStream(); // Dummy stream, replace with actual stream
        const mediaRecorder = new MediaRecorder(stream);
        return mediaRecorder.mimeType; // Use the mimeType property of the instantiated MediaRecorder
      } catch (e) {
        console.error('MediaRecorder is not supported in this browser.', e);
        return null; // or a safe fallback MIME type, if there's a sensible default for your application
      }
    }
  }

  static closeActiveStreams = (stream: MediaStream) => {
    const tracks = stream.getVideoTracks();
    for (const track of tracks) {
      track.enabled = false;
      track.stop();
      stream.removeTrack(track);
    }
  };
}

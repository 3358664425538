import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { getImageProvider } from '@freddy/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../environments/environment';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { TranslateModule } from '@ngx-translate/core';
import { PageComponent } from './components/page/page.component';
import { PhotoInputComponent } from './input/photo.input.component';
import { ArComponent } from './components/ar/ar.component';
import { TeamPhotoComponent } from './components/team-photo/team-photo.component';

@NgModule({
  providers: [DatePipe, getImageProvider(environment.firebase.storageBucket)],
  imports: [
    CommonModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    FormsModule,
    ArComponent,
    LoadingButtonComponent,
    ImageFallbackDirective,
    PageComponent,
    PhotoInputComponent,
    TeamPhotoComponent,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage,
    LoadingButtonComponent,
    ImageFallbackDirective,
    PageComponent,
    PhotoInputComponent,
    TeamPhotoComponent,
  ],
})
export class SharedModule {}
